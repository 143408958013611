<template>
  <div id="container">
    <div class="time-selector">
      <span>请选择时间范围</span>
      <a-range-picker
        :show-time="{ format: 'HH:mm:ss' }"
        format="YYYY-MM-DD HH:mm:ss"
        :placeholder="['开始时间', '结束时间']"
        @change="onChange"
        @ok="onOk"
      >
      </a-range-picker>
    </div>

    <div class="detail-box">
      <div class="top-box">
        <span
          :class="isShowTrack ? 'span-active' : ''"
          @click="changeState('track')"
          >获取轨迹</span
        >
        <span
          :class="isShowThreeHour ? 'span-active' : ''"
          @click="changeState('threeHour')"
          >3小时</span
        >
        <span
          :class="isShowOneDay ? 'span-active' : ''"
          @click="changeState('oneDay')"
          >1天</span
        >
        <span
          :class="isShowthreeDay ? 'span-active' : ''"
          @click="changeState('threeDay')"
          >3天</span
        >
      </div>
      <div class="center-box">
        <h4>轨迹详情</h4>
        <ul>
          <li>电池编号：<span>BT372878382492783</span></li>
          <li>起始时间：<span>2021-11-08 13:14:29</span></li>
          <li>
            起始地址：<span>福建省厦门市思明区梧村街道厦禾路华星大厦</span>
          </li>
          <li>结束时间：<span>2021-11-08 14:10:20</span></li>
          <li>结束地址：<span>福建省厦门市思明区莲前街道东浦路</span></li>
          <li>预估里程：<span>10.61km</span></li>
        </ul>
      </div>
      <div class="bottom-box">
        <FastBackwardOutlined @click="this.fastBack" />
        <PlayCircleOutlined @click="this.play" />
        <FastForwardOutlined @click="this.fastForward" />
        <span>播放数度x{{ speed }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import AMap from 'AMap'
import AMapUI from 'AMapUI'
import {
  FastBackwardOutlined,
  PlayCircleOutlined,
  FastForwardOutlined
} from '@ant-design/icons-vue'
var map
var pathSimplifierIns
var navg1
var baseSpeed = 200
var contentImage
var startIcon = new AMap.Icon({
  size: new AMap.Size(52, 52), // 图标尺寸
  image: '/static/start.png', // Icon的图像
  imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
  imageSize: new AMap.Size(52, 52) // 根据所设置的大小拉伸或压缩图片
})
var endIcon = new AMap.Icon({
  size: new AMap.Size(52, 52), // 图标尺寸
  image: '/static/end.png', // Icon的图像
  imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
  imageSize: new AMap.Size(52, 52) // 根据所设置的大小拉伸或压缩图片
})
var startMarker, endMarker
export default {
  data() {
    return {
      isShowTrack: false,
      isShowThreeHour: false,
      isShowOneDay: false,
      isShowthreeDay: false,
      isPlay: false,
      speed: 1,
      startTime: '',
      endTime: ''
    }
  },
  components: {
    FastBackwardOutlined,
    PlayCircleOutlined,
    FastForwardOutlined
  },
  methods: {
    // 后退播放
    fastBack() {
      if (navg1) {
        if (this.speed > 1 && this.speed <= 20) {
          this.speed--
        } else if (this.speed <= 1 && this.speed !== 0.1) {
          this.speed = (this.speed - 0.1).toFixed(1)
          this.speed = Number(this.speed)
        } else if (this.speed > 20 && this.speed <= 50) {
          this.speed -= 10
        }
        navg1.setSpeed(baseSpeed * this.speed)
      }
    },
    // 播放暂停切换
    play() {
      if (navg1) {
        this.isPlay = !this.isPlay
        if (this.isPlay) {
          if (navg1.getNaviStatus() === 'pause') {
            navg1.resume()
          } else {
            navg1.start()
          }
        } else {
          navg1.pause()
        }
      }
    },
    // 快进播放
    fastForward() {
      if (navg1) {
        if (this.speed !== 50 && this.speed >= 1) {
          if (this.speed < 20) {
            this.speed++
          } else if (this.speed >= 20) {
            this.speed += 10
          }
        } else if (this.speed < 1) {
          this.speed = Number(this.speed)
          this.speed = Number((this.speed + 0.1).toFixed(1))
          if (this.speed === 1.0) {
            this.speed = 1
          }
        }
        navg1.setSpeed(baseSpeed * this.speed)
      }
    },
    // 格式化时间
    dateFormat(data) {
      const date = new Date(parseInt(data))
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const hour = date.getHours()
      const minute = date.getMinutes()
      const seconds = date.getSeconds()
      return `${year}-${month < 9 ? '0' + month : month}-${
        day < 9 ? '0' + day : day
      } ${hour < 9 ? '0' + hour : hour}:${minute < 9 ? '0' + minute : minute}
      ${seconds < 9 ? '0' + seconds : seconds} `
    },
    // 点击按钮选择时间切换不同的轨迹状态
    changeState(state) {
      this.isPlay = false
      const currentTime = new Date()
      this.endTime = this.dateFormat(currentTime.getTime())
      if (startMarker) {
        map.remove(startMarker)
        startMarker = null
      }
      if (endMarker) {
        map.remove(endMarker)
        endMarker = null
      }
      if (state === 'track') {
        this.isShowTrack = true
        this.isShowThreeHour = false
        this.isShowOneDay = false
        this.isShowthreeDay = false
        this.startTime = this.dateFormat(
          currentTime.getTime() - 1 * 60 * 60 * 1000
        )
        this.setTrackDataByTime(1, this.startTime, this.endTime)
      } else if (state === 'threeHour') {
        this.isShowTrack = false
        this.isShowThreeHour = true
        this.isShowOneDay = false
        this.isShowthreeDay = false
        this.startTime = this.dateFormat(
          currentTime.getTime() - 3 * 60 * 60 * 1000
        )
        this.setTrackDataByTime(2, this.startTime, this.endTime)
      } else if (state === 'oneDay') {
        this.isShowTrack = false
        this.isShowThreeHour = false
        this.isShowOneDay = true
        this.isShowthreeDay = false
        this.startTime = this.dateFormat(
          currentTime.getTime() - 24 * 60 * 60 * 1000
        )
        this.setTrackDataByTime(3, this.startTime, this.endTime)
      } else if (state === 'threeDay') {
        this.isShowTrack = false
        this.isShowThreeHour = false
        this.isShowOneDay = false
        this.isShowthreeDay = true
        this.startTime = this.dateFormat(
          currentTime.getTime() - 72 * 60 * 60 * 1000
        )
        this.setTrackDataByTime(4, this.startTime, this.endTime)
      }
    },
    // 通过时间段获取trackData数据并设置trackData
    setTrackDataByTime(state, start, end) {
      let trackData = []
      if (state === 1) {
        trackData = [
          {
            name: '轨迹0',
            path: [
              ['118.08923', '24.499406'],
              ['118.08923', '24.498416'],
              ['118.08933', '24.499726'],
              ['118.08924', '24.499636']
            ]
          }
        ]
      } else if (state === 2) {
        trackData = [
          {
            name: '轨迹0',
            path: [
              ['118.08923', '24.499406'],
              ['118.08923', '24.498416'],
              ['118.05933', '24.499726'],
              ['118.06924', '24.499636']
            ]
          }
        ]
      } else if (state === 3) {
        trackData = [
          {
            name: '轨迹0',
            path: [
              ['118.08923', '24.499406'],
              ['118.08923', '24.498416'],
              ['118.03933', '24.499726'],
              ['118.02924', '24.499636']
            ]
          }
        ]
      } else if (state === 4) {
        trackData = [
          {
            name: '轨迹0',
            path: [
              ['118.08923', '24.499406'],
              ['118.08923', '24.498416'],
              ['118.03953', '24.499726'],
              ['118.02914', '24.499636']
            ]
          }
        ]
      } else if (state === 5) {
        trackData = [
          {
            name: '轨迹0',
            path: [
              ['118.08923', '24.499406'],
              ['118.08923', '24.498416'],
              ['118.03954', '24.499726'],
              ['118.02964', '24.499636']
            ]
          }
        ]
      }
      // 设置数据
      pathSimplifierIns.setData(trackData)
      // 对第一条线路（即索引 0）创建一个巡航器
      navg1 = pathSimplifierIns.createPathNavigator(0, {
        loop: false, // 循环播放
        speed: baseSpeed * this.speed, // 巡航速度，单位千米/小时
        pathNavigatorStyle: {
          content: contentImage,
          width: 32,
          height: 32
        }
      })
    },
    // 创建组件实例 pathSimplifierIns
    initPage(PathSimplifier) {
      pathSimplifierIns = new PathSimplifier({
        zIndex: 100,
        // autoSetFitView:false,
        map: map, // 所属的地图实例
        getPath: function (pathData, pathIndex) {
          const length = pathData.path.length
          startMarker = new AMap.Marker({
            position: pathData.path[0],
            icon: startIcon,
            offset: new AMap.Pixel(-25, -48)
          })
          endMarker = new AMap.Marker({
            position: pathData.path[length - 1],
            icon: endIcon,
            offset: new AMap.Pixel(-25, -48)
          })
          map.add(startMarker)
          map.add(endMarker)
          return pathData.path
        },
        getHoverTitle: function (pathData, pathIndex, pointIndex) {
          if (pointIndex >= 0) {
            // point
            return (
              pathData.name + '，点：' + pointIndex + '/' + pathData.path.length
            )
          }
          return pathData.name + '，点数量' + pathData.path.length
        },
        renderOptions: {
          renderAllPointsIfNumberBelow: 100, // 绘制路线节点，如不需要可设置为-1
          // 轨迹线的样式
          pathLineStyle: {
            strokeStyle: 'red',
            lineWidth: 6,
            dirArrowStyle: true
          }
        }
      })
      // 轨迹巡航图标
      contentImage = PathSimplifier.Render.Canvas.getImageContent(
        '/static/car.png',

        function onload() {
          // 图片加载成功，重新绘制一次

          pathSimplifierIns.renderLater()
        },

        function onerror(e) {
          alert('图片加载失败！')
        }
      )
    },
    // 初始化地图数据
    initMapData() {
      const that = this
      map = new AMap.Map('container', {
        center: [118.08923, 24.479406],
        resizeEnable: true,
        zoom: 10
      })
      AMapUI.load(
        ['ui/misc/PathSimplifier', 'lib/$'],
        function (PathSimplifier, $) {
          if (!PathSimplifier.supportCanvas) {
            alert('当前环境不支持 Canvas！')
            return
          }
          that.initPage(PathSimplifier)
        }
      )
    },
    // 时间选改变监听
    onChange(value, dateString) {
      this.startTime = dateString[0]
      this.endTime = dateString[1]
    },
    // 时间选择确认监听
    onOk(value) {
      this.isPlay = false
      this.isShowTrack = false
      this.isShowThreeHour = false
      this.isShowOneDay = false
      this.isShowthreeDay = false
      console.log('onOk: ', value)
      if (startMarker) {
        map.remove(startMarker)
        startMarker = null
      }
      if (endMarker) {
        map.remove(endMarker)
        endMarker = null
      }
      this.setTrackDataByTime(5, this.startTime, this.endTime)
    }
  },
  mounted() {
    this.initMapData()
  }
}
</script>

<style lang='less' scoped>
#container {
  height: 100vh;
  .time-selector {
    position: absolute;
    background-color: #19354b;
    z-index: 99;
    width: 550px;
    text-align: center;
    color: white;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 20px;
    top: 20px;
    left: 50%;
    margin-left: -225px;
    opacity: 0.8;
    span {
      margin-right: 20px;
    }
    & :deep(.ant-input) {
      background-color: #19354b;
      color: white;
    }
    & :deep(.ant-calendar-picker-clear) {
      background-color: #19354b;
      color: white;
    }
  }
  .detail-box {
    font-size: 14px;
    position: absolute;
    z-index: 99;
    width: 350px;
    color: white;
    top: 50%;
    right: 30px;
    opacity: 0.8;
    margin-top: -260px;
    .top-box {
      display: flex;
      flex-direction: column;
      background-color: #19354b;
      padding: 8px 10px;
      margin-bottom: 10px;
      border-radius: 15px;
      span {
        padding: 7px 0;
        width: 250px;
        text-align: center;
        background-color: #16465b;
        margin-bottom: 8px;
        border-radius: 20px;
        margin-left: 30px;
        border: 1px solid #05c6c6;
        // &:hover {
        //   background-color: #008286;
        //   opacity: 0.5;
        // }
      }
    }
    .center-box {
      background-color: #19354b;
      padding: 8px 10px;
      margin-bottom: 10px;
      border-radius: 15px;
      h4 {
        color: white;
      }
      ul {
        li {
          list-style: none;
          margin-left: -40px;
          display: flex;
          margin-bottom: 8px;
          span {
            flex: 1;
          }
        }
      }
    }
    .bottom-box {
      padding: 10px 0;
      background-color: #19354b;
      border-radius: 15px;
      text-align: center;
      align-content: center;
      & :deep(.anticon) {
        font-size: 24px;
        padding: 2px 8px;
        border: 1px solid white;
        border-radius: 5px;
        margin-right: 5px;
      }
      span {
        vertical-align: middle;
      }
    }
  }
}
.span-active {
  background-color: #008286 !important;
}
</style>
